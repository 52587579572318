<template>
  <v-app>
    <v-main>
      <!-- <SignIn/> -->
      <!-- <DashboardSkeletonVue/> -->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import DashboardSkeletonVue from './components/DashboardSkeleton.vue'
// import HelloWorld from './components/HelloWorld.vue'
// import NavigationDrawer from './components/NavigationDrawer.vue';
// import SignIn from './components/SignIn.vue';


export default {
  name: 'App',

  components: {

    // DashboardSkeletonVue
    // SignIn
  },

  data: () => ({
    //
  }),
}
</script>
