import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./route/index.js";
import { MotionPlugin } from "@vueuse/motion";
import VueWriter from "vue-writer";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {registerLicense} from '@syncfusion/ej2-base';
import { store } from "@/store/store";
registerLicense("Ngo9BigBOggjHTQxAR8/V1NBaF1cWWhPYVJ/WmFZfVpgdVdMYVVbRn9PMyBoS35RckVmW39ecHBURmhUVkR+");

// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

loadFonts();

createApp(App)
  .use(vuetify)
  .use(router)
  .use(VueSweetalert2)
  .use(MotionPlugin)
  .use(VueWriter)
  .use(store)
  .component('VueDatePicker', VueDatePicker)
  // .use(IconsPlugin)
  // .use(BootstrapVue)
  .mount("#app");
