import { createStore } from "vuex";
export const store = createStore({
  state: {
    user:{},
    user_role:localStorage.getItem('userRole'),
    user_role_name: localStorage.getItem('userRoleName'),
    user_permissions:[],
  },
  getters: {},
  mutations: {},
  actions: {},
});
